// React
import { Fragment } from "react";

// Styled Components
import styled from "styled-components";

// Components
import FaqItem from "./FaqItem/FaqItem";

const Faq = () => {
  return (
    <Fragment>
      <div id="faq"></div>
      <Wrapper>
        <p className="header">Faq</p>
        <FaqItem
          title={"How does this work?"}
          info={`We partner with your favorite NFT projects to bring you custom merch through NFT mint. Each mint earns you one derivative artwork NFT as well as one merch item. Once minted, owners can then input their wallet key and mailing address anonomously on our site. Wallets are then verified, shipping labels printed, packages sent, and information destroyed.`}
          pre={false}
        />
        <FaqItem
          title={"When is the next Pop Up?"}
          info={`Pop Ups will be announced through our Twitter page @PopUpShopNFT and Discord channel. Additionally, NFT projects in collaboration with Pop Up Shop will alert you through their own channels.`}
          pre={false}
        />

        <FaqItem
          title={"How many will be available?"}
          info={`Each NFT collaboration will be unique. Keep up with new releases and quantites available by following us on Twitter.`}
          pre={false}
        />

        <FaqItem
          title={"What does each mint include?"}
          info={`With each mint you will recieve one custom NFT + one physical handcrafted merch item shipped to you.`}
          pre={false}
        />

<FaqItem
          title={"What personal information is needed to recieve my merch?"}
          info={`Mailing address and wallet key will be all that is needed to verify NFT purchase + to recieve merch.`}
          pre={false}
        />


<FaqItem
          title={"How does sizing work?"}
          info={`Current items being sold are one size fits all (think assesories, hats, bags, backpacks, cups, stickers, etc..).`}
          pre={false}
        />
      </Wrapper>
    </Fragment>
  );
};

export default Faq;

const Wrapper = styled.div`
  &&&&&&& {
    width: 100%;
    height: max-content;

    padding: 0 25px;
    padding-top: 100px;

    color: #fff;
    text-align: center;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;

    .header {
      font-size: 2.25rem;
      padding-bottom: 30px;
    }
  }
`;
