// Styled Components
import styled from "styled-components";

// Components
import MintInfo from "./MintInfo/MintInfo";
import MintButton from "./MintButton/MintButton";

interface Props {
  minter: any;
}

const Mint = (props: Props) => {
  return (
    <Wrapper id="mint">
      {/* CHANGE SRC TO NEW IMAGE */}
      <img src="https://cdn.discordapp.com/attachments/902246389881376839/903741541225472050/NFT1XMERCH.PNG" alt="" className="change" />
      <img
        src="https://cdn.discordapp.com/attachments/902246389881376839/903746196428951563/Screen_Shot_2021-10-29_at_2.44.30_PM.png"
        alt=""
        className="change"
      />
      <MintInfo />
      <MintButton minter={props.minter} />
    </Wrapper>
  );
};

export default Mint;

const Wrapper = styled.div`
  padding: 40px 0;
  height: min-content;
  z-index: 1000;
  margin: 0 auto;
  margin-top: 50px;

  position: relative;

  display: flex;
  flex-direction: column;
  gap: 40px;

  width: 550px;
  @media only screen and (max-width: 575px) {
    width: 95%;
  }

  .change {
    width: 100%;
    height: 275px;
  }

  border: 1px solid #c89fff;
  border-radius: 10px;
`;
