// React
import { Fragment } from "react";

// Styled Components
import styled from "styled-components";

// Components
import RoadmapItem from "./RoadmapItem/RoadmapItem";

const Roadmap = () => {
  return (
    <Fragment>
      <Wrapper id="roadmap">
        <p className="header">Road Map</p>

        <div className="line-wrapper">
          <div className="roadmap-line"></div>
        </div>

        <div className="items">
          <RoadmapItem
            align={"left"}
            img={
              "https://cdn.discordapp.com/attachments/901462043591327765/901813966857179136/image0.png"
            }
            title={"Collaborations"}
            info={`Collaborating with different NFT projects to mint derivative art and make custom merch. Bringing e-commerce to NFT's. Each NFT earns you 1x merch item. Anonymous shipping on the blockchain`}
          />
          <RoadmapItem
            align={"right"}
            img={
              "https://cdn.discordapp.com/attachments/901462043591327765/901813957097037834/image0.png"
            }
            title={"Merch Design"}
            info={`50% of proceeds will be used to cover merch costs. Paying careful attention to detail. Quality over quantity is what we aim for`}
            rotateImage={true}
          />
          <RoadmapItem
            align={"left"}
            img={
              "https://cdn.discordapp.com/attachments/901462043591327765/901813990093619210/image0.png"
            }
            title={"Community Growth"}
            info={`25% of proceeds will be donated to treasury wallet of project in collaboration.`}
          />
          <RoadmapItem
            align={"right"}
            img={
              "https://cdn.discordapp.com/attachments/901462043591327765/901813978508959774/image0.png"
            }
            title={"Supporting Small Business"}
            info={`25% of proceeds will go back to the merch shop and artists who helped design. Partnered with and supporting small business.
            `}
          />
        </div>
      </Wrapper>
    </Fragment>
  );
};

export default Roadmap;

const Wrapper = styled.div`
  width: 100%;
  color: #fff;
  padding: 0 25px;
  padding-top: 150px;

  .header {
    text-align: center;
    font-size: 2.25rem;
    padding-bottom: 50px;
    font-family: interstate, sans-serif;
    font-weight: 900;
    font-style: normal;
  }

  .line-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;

    width: 100%;
    height: 100%;
    z-index: -1;

    //
    @media only screen and (max-width: 810px) {
      width: 500px;
      margin: 0 auto;
    }

    @media only screen and (max-width: 580px) {
      width: 100%;
    }
    //
  }

  .roadmap-line {
    width: 5px;

    border-radius: 20px;
    background-color: #3e3e3e;

    //
    height: 1100px;
    @media only screen and (max-width: 810px) {
      height: 1050px;
    }
    @media only screen and (max-width: 580px) {
      height: 1150px;
    }
    @media only screen and (max-width: 450px) {
      height: 1150px;
    }
    @media only screen and (max-width: 410px) {
      height: 1150px;
    }
    @media only screen and (max-width: 390px) {
      height: 1250px;
    }
    //

    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    //
    @media only screen and (max-width: 810px) {
      left: 91px;
    }

    @media only screen and (max-width: 580px) {
      left: 130px;
    }
    //
  }

  .items {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 100px;
  }
`;
