// Styled Components
import styled from "styled-components";

// Components
import TeamMember from "./TeamMember/TeamMember";

const Team = () => {
  return (
    <Wrapper>
      <p className="header">Team Members</p>

      <TeamMember
        img={"https://cdn.discordapp.com/attachments/901462043591327765/901814203722117120/image0.png"}
        header={"Decentraleyezd"}
        info={"Project Creator"}
      />

<TeamMember
        img={"https://cdn.discordapp.com/attachments/901462043591327765/901879238729285713/Screen_Shot_2021-10-24_at_11.05.40_AM.png"}
        header={"South Park Embroidery"}
        info={"Small Business Support"}
      />
    </Wrapper>
  );
};

export default Team;

const Wrapper = styled.div`
  width: 100%;
  height: max-content;
  margin-top: 50px;

  @media only screen and (min-width: 625px) {
    padding: 0 25px;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;

  .header {
    font-size: 2.25rem;
    color: #fff;
  }

  .team {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 25px;

    &-single {
      gap: 0;
      flex-wrap: nowrap;
      align-items: center;

      //   display: block;
      //  width: 100%;
    }
  }
`;
